/* You can add global styles to this file, and also import other style files */
@import  "../node_modules/primeicons/primeicons.css";
@import  "../node_modules/primeng/resources/themes/nova-light/theme.css";
@import  "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/font-awesome/css/font-awesome.css";

/*Prime Ng*/
@keyframes ui-progress-spinner-color {
  100%,
  0% {
      stroke: #d62d20;
  }
  40% {
      stroke: #0057e7;
  }
  66% {
      stroke: #008744;
  }
  80%,
  90% {
      stroke: #ffa700;
  }
}

.ui-fieldset-legend-text {
  font-weight: bold !important;
}
/*Custom css styles*/


.margin-top-95{
    margin-top: 95px !important;
}

.footer-desing{
  background-color:#337ab7 !important;
  color: white !important;
  margin-bottom: 0px !important;
}

.gray-border{
    border: 1px solid gray !important;
}

/*Header Background Image*/
.header{
    background-attachment: scroll;
    height:300px;
    padding-top:0px;
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

@media(min-width:576)
{
  .header{
      padding-top:315px;
      height:600px;
  }
}

.content{
  text-align: left;
  width:40%;
  padding-left:10%;
  color:#fff;
}

.margin-top-50{
  margin-top: 50px;
}
.margin-bottom-50{
  margin-bottom: 50px;
}

.padding-bottom-50{
  padding-bottom: 50px;
}

.padding-top-50{
  padding-top: 50px;
}

.font-size-17 {
  font-size: 19px;
}

.cursor-pointer{
  cursor: pointer;
}

.alert-font-size{
  font-size: 26px;
  font-weight: bold;
}
